import React from 'react'
import './IconButton.css'

export interface IconButtonProps
  extends Pick<React.HTMLAttributes<HTMLSpanElement>, 'onClick'> {
  style?: React.StyleHTMLAttributes<HTMLSpanElement>
  color: string
  Icon: () => JSX.Element
  label: string
}

const IconButton: React.FC<IconButtonProps> = ({
  Icon,
  color = 'black',
  label,
  style = {},
  ...props
}) => {
  return (
    <span
      className='IconButton'
      style={{ backgroundColor: color, ...style }}
      {...props}
    >
      <div className='icon'>
        <Icon />
      </div>
      <span className='label'>{label}</span>
    </span>
  )
}

export default IconButton
