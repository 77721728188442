import { AndroidFilled, AppleFilled } from '@ant-design/icons'
import * as React from 'react'
import IconButton from '../../components/IconButton'
import './ChooseDevicePage.css'

export interface ChooseDevicePageProps {
  onDeviceSelect: (device: 'android' | 'ios') => void
}

const ChooseDevicePage: React.FC<ChooseDevicePageProps> = ({
  onDeviceSelect,
}) => {
  return (
    <div className='ChooseDevicePage'>
      <div className='heading'>
        Select your device to unlock your Promo Code for Aspire:
      </div>
      <IconButton
        color='black'
        label='iPhone/iPad'
        Icon={() => <AppleFilled />}
        onClick={() => onDeviceSelect('ios')}
      />
      <IconButton
        color='#ACC04F'
        label='Android'
        Icon={() => <AndroidFilled />}
        onClick={() => onDeviceSelect('android')}
      />
    </div>
  )
}

export default ChooseDevicePage
