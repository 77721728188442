import firebase from 'firebase/app'

// Cheeky way of adding universal prefix via template literals
const event_ = (str: TemplateStringsArray) => 'promo_code_' + str

export class AnalyticsService {
  instanceId: string | null = null

  setInstanceId(id: string) {
    this.instanceId = id
  }

  private logEvent = (name: string, data: any = {}) => {
    const eventData = { instanceId: this.instanceId, ...data }
    if (process.env.NODE_ENV === 'development') eventData.dev = true
    firebase.analytics().logEvent(name, eventData)
    //@ts-expect-error
    const klaviyo = _learnq || []
    klaviyo.push(['track', name, eventData])
  }

  logPageLoad = () => this.logEvent(event_`page_load`)

  logRedeem = (data: { platform: string }) =>
    this.logEvent(event_`redeem`, data)

  logStoreRedirect = (data: { store: string }) =>
    this.logEvent(event_`store_redirect`, data)
}

export default new AnalyticsService()
