import axios from 'axios'
import qs from 'querystring'
import appenv from '../.envconfig.js'

export type CodeData = {
  code: string
  platform: string
  alreadyRedeemed?: boolean
}

function isCodeData(value: any): value is CodeData {
  return (
    value &&
    typeof value === 'object' &&
    ['code', 'platform'].every(p => p in value)
  )
}

const api = axios.create({
  baseURL: appenv.functionsBaseUrl + '/promo_codes',
})

export async function redeemInstance(instanceId: string, platform: string) {
  const { data } = await api.get(
    `/redeem?${qs.encode({
      instanceId,
      platform,
    })}`
  )
  if (isCodeData(data)) return data
  // Object is not code data and thus not valid/useful
  return null
}

export async function findCode(instanceId: string) {
  const { data } = await api.get(
    `/find?${qs.encode({
      instanceId,
    })}`
  )
  if (isCodeData(data)) return data
  // Object is not code data and thus not valid/useful
  return null
}

export const getRedirectUrl = (platform: 'ios' | 'android', code: string) =>
  ({
    ios: `https://apps.apple.com/redeem?ctx=offercodes&id=1326864157&code=${code}`,
    android: `https://play.google.com/redeem?code=${code}`,
  }[platform])
