module.exports = {
  apiKey: 'AIzaSyATH7WC42rI2mvVd9N4BW_iiKXevHOF5UI',
  authDomain: 'prana-heart.firebaseapp.com',
  databaseURL: 'https://prana-heart.firebaseio.com',
  projectId: 'prana-heart',
  storageBucket: 'prana-heart.appspot.com',
  messagingSenderId: '403277030006',
  appId: '1:403277030006:web:cdb2cad2412a46007ef557',
  measurementId: 'G-J9NZR21BV5',
}
