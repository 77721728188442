import qs from 'querystring'
import { default as React, useCallback, useEffect, useRef } from 'react'
import * as RedemptionService from '../../services/redemption'
import RootContent from './RootContent'
import ans from '../../services/analytics'

const defaultErrorText =
  'Unfortunately, this is not a valid redemption link. If you think this is an error, please respond back to the email that was sent to you!'

function getDeviceType(): 'ios' | 'android' | 'other' {
  const userAgent = navigator.userAgent || navigator.vendor
  if (/android/i.test(userAgent)) return 'android'
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'ios'
  return 'other'
}

function getQueryParams(searchString: string) {
  return qs.decode(searchString.substring(1))
}

const Root: React.FC = () => {
  // Get parameters of data
  const queryParams = useRef(getQueryParams(window.location.search)).current

  // Undefined == loading, null == no code found
  const [codeData, setCodeData] = React.useState<
    RedemptionService.CodeData | null | undefined
  >(undefined)
  const [error, setError] = React.useState<string | undefined>(undefined)

  // See if data has already been fetched, if so then show and skip code pick
  useEffect(() => {
    // set analytics instance ID regardless of whether it exists
    ans.setInstanceId(queryParams?.id?.toString() ?? null)
    // log page load
    ans.logPageLoad()

    if (!['id'].every(k => k in queryParams)) {
      setError(defaultErrorText)
      return
    }

    const platform = getDeviceType()

    RedemptionService.findCode(queryParams.id.toString())
      .then(async data => {
        console.log(data)
        // If they previously accessed this page or this is a non-recognized platform, then show the code
        // Reason for the latter is to prevent them from entering a continuous loop of never getting access to the code
        //  in case of an error farther fown the pipeline
        if (data || platform === 'other') {
          setCodeData(data)
          return null
        }
        // If we know the platform then redeem code and redirect automatically to respective appstore redeem page
        const newCodeData = await RedemptionService.redeemInstance(
          queryParams.id.toString(),
          platform
        )
        // If could not be retrieved then show error
        if (!newCodeData) {
          setCodeData(null)
          setError(defaultErrorText)
        } else {
          ans.logStoreRedirect({ store: platform })
          window.location.replace(
            RedemptionService.getRedirectUrl(platform, newCodeData.code)
          )
        }
      })
      .catch(e => {
        console.log(e)
        setCodeData(null)
        setError(defaultErrorText)
      })
  }, [])

  const requestPlatformCode = useCallback(
    async platform => {
      try {
        setCodeData(undefined)
        const data = await RedemptionService.redeemInstance(
          queryParams.id.toString(),
          platform
        )
        ans.logRedeem({ platform: platform })
        setCodeData(data)
      } catch (e) {
        setCodeData(null)
      }
    },
    [queryParams]
  )

  return (
    <RootContent
      codeData={codeData}
      error={error}
      onDeviceSelect={platform => requestPlatformCode(platform)}
    />
  )
}

export default Root
