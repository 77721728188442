import * as React from 'react'
import logo from '../assets/img/full-logo.png'

const PageHeader: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 10,
      }}
    >
      <a href='https://www.aspiremeditation.com/'>
        <img style={{ height: 48 }} src={logo} />
      </a>
    </div>
  )
}

export default PageHeader
