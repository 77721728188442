import * as React from 'react'
import './CodeBox.css'

export interface CodeBoxProps {
  code: string
}

const CodeBox: React.FC<CodeBoxProps> = ({ code }) => {
  return (
    <div className='CodeBox'>
      <span className='code-text'>{code}</span>
    </div>
  )
}

export default CodeBox
