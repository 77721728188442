import React, { useMemo } from 'react'
import { Text } from 'rebass'
import PageHeader from '../../components/PageHeader'
import { CodeData } from '../../services/redemption'
import ChooseDevicePage, { ChooseDevicePageProps } from './ChooseDevicePage'
import CodeUnlockedPage from './CodeUnlockedPage'
import './RootContent.css'
//@ts-ignore
import { Puff } from 'svg-loaders-react'

export interface RootContentProps {
  codeData?: CodeData | null
  error?: string
  onDeviceSelect: ChooseDevicePageProps['onDeviceSelect']
}

const RootContent: React.FC<RootContentProps> = ({
  codeData,
  error,
  onDeviceSelect,
}) => {
  const mainContent = useMemo(() => {
    const loading = codeData === undefined

    if (codeData != null) {
      return <CodeUnlockedPage code={codeData.code} />
    }

    if (error)
      return (
        <div>
          <Text
            fontSize={[2, 3]}
            style={{
              color: 'red',
              maxWidth: 768,
              textAlign: 'center',
              marginTop: 8,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            {error}
          </Text>
        </div>
      )

    if (loading) return <Puff style={{ marginTop: 16 }} stroke='#ec008c' />

    return <ChooseDevicePage onDeviceSelect={onDeviceSelect} />
  }, [codeData, error, onDeviceSelect])

  return (
    <div className='RootContent'>
      <PageHeader />
      {mainContent}
    </div>
  )
}

export default RootContent
