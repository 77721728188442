import * as React from 'react'
import './CodeUnlockedPage.css'
import CodeBox from '../../components/CodeBox'
// @ts-expect-error
import { useSnackbar } from 'react-simple-snackbar'

export interface CodeUnlockedPageProps {
  code: string
}

const CodeUnlockedPage: React.FC<CodeUnlockedPageProps> = ({ code }) => {
  const [openSnackbar, closeSnackbar] = useSnackbar()

  return (
    <div className='CodeUnlockedPage'>
      <span className='heading'>Promo Code unlocked!</span>
      <div className='flavor-text'>
        Welcome to the Aspire community.
        <br />
        We have a wide range of meditations that suits to every walk of today's
        modern woman.
      </div>
      <span className='codebox-title'>Promo Code:</span>
      <CodeBox code={code} />
      <span
        className='copy-button'
        onClick={() => {
          navigator.clipboard.writeText(code)
          openSnackbar('Code copied to clipboard!')
        }}
      >
        Copy Code
      </span>
    </div>
  )
}

export default CodeUnlockedPage
