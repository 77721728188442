import React from 'react'
import { ThemeProvider } from 'emotion-theming'
// @ts-expect-error
import theme from '@rebass/preset'
import Root from './routes'
// @ts-expect-error
import SnackbarProvider from 'react-simple-snackbar'

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Root />
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
